import React, { useMemo } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SystemProvider } from '@rtgdev/design-system'

import ReduxProvider from '../redux/store'

// eslint-disable-next-line react/prop-types
export default function AppProvider({ children }) {
  // do not export it but instead use `useClient` from react query
  const client = useMemo(() => new QueryClient(), [])
  return (
    <SystemProvider>
      <ReduxProvider>
        <QueryClientProvider client={client}>{children}</QueryClientProvider>
      </ReduxProvider>
    </SystemProvider>
  )
}

// eslint-disable-next-line react/prop-types
export const gatsbyAppWrapper = ({ element }) => <AppProvider>{element}</AppProvider>
