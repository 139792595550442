// Actions
export const RESET_ORDER_STATUS = 'ORDER_STATUS/RESET_ORDER_STATUS'
export const SET_ORDER_LIST = 'ORDER_STATUS/SET_ORDER_LIST'
export const SET_ORDER = 'ORDER_STATUS/SET_ORDER'
export const SET_PREMIUM_DELIVERY = 'ORDER_STATUS/SET_PREMIUM_DELIVERY'
export const SET_PARTIAL_PAYMENT = 'ORDER_STATUS/SET_PARTIAL_PAYMENT'
export const SET_PAYMENTS_APPLIED = 'ORDER_STATUS/SET_PAYMENTS_APPLIED'
export const SET_ORDER_NUMBER = 'ORDER_STATUS/SET_ORDER_NUMBER'
export const SET_FINANCE_PAYMENT = 'ORDER_STATUS/SET_FINANCE_PAYMENT'
export const SET_CREDIT_CARD_PAYMENT = 'ORDER_STATUS/SET_CREDIT_CARD_PAYMENT'
export const SET_PREMIUM_CHARGE = 'ORDER_STATUS/SET_PREMIUM_CHARGE'

// Action Creators
export function resetOrderStatus() {
  return { type: RESET_ORDER_STATUS, payload: null }
}
export function setOrderList(orderList) {
  return { type: SET_ORDER_LIST, payload: orderList }
}

export function setOrder(order) {
  return { type: SET_ORDER, payload: order }
}

export function setPartialPayment({ partialPaymentValue, isFullPayment }) {
  return {
    type: SET_PARTIAL_PAYMENT,
    payload: {
      isFullPayment,
      partialPaymentValue: partialPaymentValue === null ? null : partialPaymentValue.replace(/[^0-9.]/, ''),
    },
  }
}

export function setPremiumDelivery(premiumDelivery) {
  return { type: SET_PREMIUM_DELIVERY, payload: premiumDelivery }
}

export function setPaymentsApplied(payments) {
  return { type: SET_PAYMENTS_APPLIED, payload: payments }
}

export function setOrderNumber(orderNumber) {
  return { type: SET_ORDER_NUMBER, payload: orderNumber }
}

export function setFinancePayment(financePayment) {
  return { type: SET_FINANCE_PAYMENT, payload: financePayment }
}

export function setCreditCardPayment(creditcardPayment) {
  return { type: SET_CREDIT_CARD_PAYMENT, payload: creditcardPayment }
}

export function setPremiumDeliveryCharge(charge) {
  return { type: SET_PREMIUM_CHARGE, payload: charge }
}

// Reducer
export const initialFinancePayment = {
  accountNumber: '',
  authAmount: 0,
  uvFinancePlan: '',
  encryptedFinancePlans: '',
}

export const initialCreditcardPayment = {
  token: '',
  authAmount: 0,
  expMonthYear: '',
}

const initialState = {
  order: {},
  orderNumber: '',
  orderList: [],
  isFullPayment: true,
  partialPaymentValue: null,
  premiumDelivery: false,
  premiumDeliveryCharge: {},
  paymentsApplied: 0,
  financePayment: initialFinancePayment,
  creditcardPayment: initialCreditcardPayment,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ORDER_LIST:
      return { ...state, orderList: payload }
    case SET_ORDER:
      return { ...state, order: payload }
    case SET_PARTIAL_PAYMENT:
      return {
        ...state,
        partialPaymentValue: payload.isFullPayment ? null : payload.partialPaymentValue,
        isFullPayment: payload.isFullPayment,
      }
    case SET_PREMIUM_DELIVERY:
      return { ...state, premiumDelivery: payload }
    case SET_PAYMENTS_APPLIED:
      return {
        ...state,
        paymentsApplied: payload.reduce(
          (accumulator, currentValue) => Number(accumulator) + Number(currentValue?.AuthorizedAmount),
          0,
        ),
      }
    case SET_ORDER_NUMBER:
      return { ...state, orderNumber: payload }
    case SET_FINANCE_PAYMENT:
      return { ...state, financePayment: { ...state.financePayment, ...payload } }
    case SET_CREDIT_CARD_PAYMENT:
      return { ...state, creditcardPayment: { ...state.creditcardPayment, ...payload } }
    case RESET_ORDER_STATUS:
      return initialState
    case SET_PREMIUM_CHARGE:
      return { ...state, premiumDeliveryCharge: payload }
    default:
      return state
  }
}
