// Actions
export const SET_EMAIL_SUBSCRIBE_ERROR = 'EMAIL/SET_EMAIL_SUBSCRIBE_ERROR'
export const SET_EMAIL_SUBSCRIBE_SUCCESS = 'EMAIL/SET_EMAIL_SUBSCRIBE_SUCCESS'
export const SET_EMAIL_SUBSCRIBE_LOADING = 'EMAIL/SET_EMAIL_SUBSCRIBE_LOADING'
export const EMAIL_SIGNUP = 'EMAIL/EMAIL_SIGNUP'
export const SET_LOADING = 'EMAIL/SET_LOADING'
export const SET_ERROR = 'EMAIL/SET_ERROR'
export const SET_SUCCESS = 'EMAIL/SET_SUCCESS'

// Action Creators
export function emailSignup(email, zip) {
  return { type: EMAIL_SIGNUP, email, zip }
}

export function setLoading(loading) {
  return { type: SET_LOADING, loading }
}

export function setError(error) {
  return { type: SET_ERROR, error }
}

export function setSuccess(success) {
  return { type: SET_SUCCESS, success }
}
// Reducer
const initialState = {
  error: false,
  success: false,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case SET_SUCCESS:
      return {
        ...state,
        success: action.success,
      }
    default:
      return state
  }
}
