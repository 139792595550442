import { isMobileWindow, isLandscapeWindow } from '@helpers/deviceWindow'

// Actions
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const SET_IS_LANDSCAPE = 'SET_IS_LANDSCAPE'
export const SET_SHOW_SEARCH_RESULTS = 'SET_SHOW_SEARCH_RESULTS'
export const SET_PLP_GRID_WIDTH = 'SET_PLP_GRID_WIDTH'

// Action Creators
export function setIsMobile(isMobile) {
  return { type: SET_IS_MOBILE, isMobile }
}

export function setIsLandscape(isLandscape) {
  return { type: SET_IS_LANDSCAPE, isLandscape }
}

export function setShowSearchResults(showSearchResults) {
  return { type: SET_SHOW_SEARCH_RESULTS, showSearchResults }
}

// Reducer
const initialState = {
  isMobile: isMobileWindow(),
  isLandscape: isLandscapeWindow(),
  showSearchResults: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      }
    case SET_IS_LANDSCAPE:
      return {
        ...state,
        isLandscape: action.isLandscape,
      }
    case SET_SHOW_SEARCH_RESULTS:
      return {
        ...state,
        showSearchResults: action.showSearchResults,
      }
    default:
      return state
  }
}
