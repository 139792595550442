import Cookies from 'universal-cookie'
import { regionLocations } from '@mocks/locationDataMocks'
import { isEqual } from 'lodash'

const cookies = new Cookies()

// Actions
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_STATE_EXCLUSIONS = 'SET_STATE_EXCLUSIONS'

// Action Creators
export function setShippingAddress(shipping_address) {
  return { type: SET_SHIPPING_ADDRESS, shipping_address }
}

export function setLocation(rtg_location = { ...initialState.rtg_location }) {
  cookies.remove('rtg_version')
  cookies.remove('__Host-rtg_version')
  const cookieLoc = cookies.get('__Host-rtg_location')
  const cookieReg = cookies.get('__Host-rtg_region')
  const cookieZip = cookies.get('__Host-rtg_zip')
  const cookieDist = cookies.get('__Host-rtg_distribution_index')
  const cookieOptions = { secure: true, maxAge: 2592000, httpOnly: false, path: '/', sameSite: 'lax' }
  if (!cookieLoc || !isEqual(rtg_location, cookieLoc)) {
    if (rtg_location.state === '') {
      if (rtg_location.region === 'PR') rtg_location.state = 'Puerto Rico'
    }
    cookies.set('__Host-rtg_location', rtg_location, cookieOptions)
  }
  if (!cookieReg || rtg_location.region !== cookieReg) {
    cookies.set('__Host-rtg_region', rtg_location.region, cookieOptions)
  }
  if (!cookieZip || rtg_location.zip !== cookieZip) {
    cookies.set('__Host-rtg_zip', rtg_location.zip, cookieOptions)
  }
  if (!cookieDist || rtg_location.distribution_index !== cookieDist) {
    cookies.set('__Host-rtg_distribution_index', rtg_location.distribution_index, cookieOptions)
  }
  return { type: SET_LOCATION, rtg_location }
}

export function setStateExclusions(state_exclusions) {
  return { type: SET_STATE_EXCLUSIONS, state_exclusions }
}

// Reducer
const initialState = {
  shipping_address: process.env.GATSBY_RTG_REGION === 'PR' ? 'Aguadilla, PR' : 'Seffner, FL',
  rtg_location: regionLocations[process.env.GATSBY_RTG_REGION || 'FL'],
  state_exclusions: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shipping_address: action.shipping_address,
      }
    case SET_LOCATION:
      return {
        ...state,
        rtg_location: action.rtg_location,
      }
    case SET_STATE_EXCLUSIONS:
      return {
        ...state,
        state_exclusions: action.state_exclusions,
      }
    default:
      return state
  }
}
