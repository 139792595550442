import { saveLocalStorage } from '@helpers/storage'

// Actions
export const SET_CART = 'SET_CART'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const UPDATE_CART_PRODUCT_QUANTITY = 'UPDATE_CART_PRODUCT_QUANTITY'
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const ADD_ACTIVE_ADDON = 'ADD_ACTIVE_ADDON'
export const REMOVE_ACTIVE_ADDON = 'REMOVE_ACTIVE_ADDON'
export const SET_WARRANTY_ENABLED = 'SET_WARRANTY_ENABLED'
export const SET_STORE_CART_CREATION_DATA = 'CART/SET_STORE_CART_CREATION_DATA'
export const SET_ADD_ON_WARRANTY_ENABLED = 'SET_ADD_ON_WARRANTY_ENABLED'

// Action Creators
export const setCart = cart => {
  if (cart && cart.cartItems && cart.cartItems.length > 0) {
    saveLocalStorage('cart', cart)
  }
  return { type: SET_CART, cart }
}

export const setStoreCartCreationData = storeCartCreationData => ({
  type: SET_STORE_CART_CREATION_DATA,
  storeCartCreationData,
})

export const addProductToCart = product => ({ type: ADD_PRODUCT_TO_CART, product })

export const updateCartProductQuantity = (sku, quantity) => ({ type: UPDATE_CART_PRODUCT_QUANTITY, sku, quantity })

export const removeProductFromCart = sku => ({ type: REMOVE_PRODUCT_FROM_CART, sku })

export const clearCart = () => ({ type: CLEAR_CART })

export const addActiveAddon = (productSku, addon, cartIndex) => ({
  type: ADD_ACTIVE_ADDON,
  productSku,
  addon,
  cartIndex,
})

export const removeActiveAddon = (productSku, addonSku, cartIndex) => ({
  type: REMOVE_ACTIVE_ADDON,
  productSku,
  addonSku,
  cartIndex,
})

export const setWarrantyEnabled = (sku, warrantyEnabled, cartIndex) => ({
  type: SET_WARRANTY_ENABLED,
  sku,
  warrantyEnabled,
  cartIndex,
})

export const setAddOnWarrantyEnabled = (sku, warrantyEnabled, cartIndex) => ({
  type: SET_ADD_ON_WARRANTY_ENABLED,
  sku,
  warrantyEnabled,
  cartIndex,
})

// Reducer
const initialState = {
  cart: {
    cartItems: [],
  },
  storeCart: null,
  storeCartCreationData: null,
}

export default (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case SET_CART:
      return {
        ...newState,
        cart: action.cart,
      }
    case SET_STORE_CART_CREATION_DATA:
      return {
        ...newState,
        storeCartCreationData: action.storeCartCreationData,
      }
    case ADD_PRODUCT_TO_CART:
      newState.cart.cartItems.push(action.product)
      saveLocalStorage('cart', newState.cart)
      return {
        ...state,
        cart: { ...newState.cart },
      }
    case UPDATE_CART_PRODUCT_QUANTITY:
      newState.cart.cartItems.forEach(obj => {
        newState.cart.cartItems.find(product => product.sku === action.sku).quantity = parseInt(action.quantity) || obj
      })
      saveLocalStorage('cart', newState.cart)
      return {
        ...state,
        cart: { ...newState.cart },
      }
    case REMOVE_PRODUCT_FROM_CART: {
      const index = newState.cart.cartItems.findIndex(product => product.sku === action.sku)
      newState.cart.cartItems.splice(index, 1)
      saveLocalStorage('cart', newState.cart)
      return {
        ...state,
        cart: { ...newState.cart },
      }
    }
    case CLEAR_CART:
      saveLocalStorage('cart', { cartItems: [] })
      return {
        ...state,
        cart: { cartItems: [] },
      }
    case ADD_ACTIVE_ADDON: {
      const product = newState.cart.cartItems[action.cartIndex]
      product.activeAddons = product.activeAddons ? product.activeAddons : []
      product.activeAddons.push(action.addon)
      newState.cart.cartItems[action.cartIndex] = product
      saveLocalStorage('cart', newState.cart)
      return {
        ...state,
        cart: { ...newState.cart },
      }
    }
    case REMOVE_ACTIVE_ADDON: {
      const product = newState.cart.cartItems[action.cartIndex]
      const addonIndex = product.activeAddons.findIndex(addon => addon.sku === action.addonSku)
      product.activeAddons.splice(addonIndex, 1)
      product.activeAddons = product.activeAddons.length === 0 ? null : product.activeAddons
      newState.cart.cartItems[action.cartIndex] = product
      saveLocalStorage('cart', newState.cart)
      return {
        ...state,
        cart: { ...newState.cart },
      }
    }
    case SET_WARRANTY_ENABLED: {
      newState.cart.cartItems.map((product, index) => {
        if (index === action.cartIndex && product.sku === action.sku) {
          product.warrantyEnabled = action.warrantyEnabled
        }
        return product
      })
      saveLocalStorage('cart', newState.cart)
      return {
        ...state,
        cart: { ...newState.cart },
      }
    }
    case SET_ADD_ON_WARRANTY_ENABLED: {
      newState.cart.cartItems.map((product, index) => {
        if (index === action.cartIndex) {
          if (product.activeAddons) {
            product.activeAddons.map(addon => {
              if (addon.sku === action.sku) {
                addon.warrantyEnabled = action.warrantyEnabled
              }
              return addon
            })
          }
        }
        return product
      })
      saveLocalStorage('cart', newState.cart)
      return {
        ...state,
        cart: { ...newState.cart },
      }
    }
    default:
      return state
  }
}
