import { gatsbyAppWrapper } from '@templates/app-provider'
import ReactDOM from 'react-dom'

export const wrapRootElement = gatsbyAppWrapper
export const disableCorePrefetching = () => true

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
