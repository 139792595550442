export const isMobileWindow = () => typeof window !== 'undefined' && window.innerWidth <= 1024

export const isDesktopWindow = () => typeof window !== 'undefined' && window.innerWidth > 1024

export const isLandscapeWindow = () =>
  typeof window !== 'undefined' &&
  window.innerWidth <= 850 &&
  window.innerWidth >= 374 &&
  window.devicePixelRatio >= 2 &&
  window.innerHeight < window.innerWidth
