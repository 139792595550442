import React from 'react'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createStore, compose, combineReducers, applyMiddleware } from 'redux'

import global from './modules/global'
import location from './modules/location'
import cart from './modules/cart'
import checkout from './modules/checkout'
import product from './modules/product'
import emailSubscribe from './modules/email-subscribe'
import orderStatus from './modules/orderStatus'

const rootReducer = combineReducers({
  global,
  location,
  cart,
  checkout,
  product,
  emailSubscribe,
  orderStatus,
})

let composeEnhancers = compose
const reduxSagaMonitorOptions = {}

if (process.env.NODE_DEV !== 'production' && typeof window === 'object') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
}

const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
const middlewares = [sagaMiddleware]
const enhancers = [applyMiddleware(...middlewares)]

export const store = createStore(rootReducer, {}, composeEnhancers(...enhancers))
store.runSaga = sagaMiddleware.run
store.injectedReducers = {}
store.injectedSagas = {}
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ children }) => <Provider store={store}>{children}</Provider>
