// Actions
export const SET_STOCK_MESSAGE = 'PRODUCT/SET_STOCK_MESSAGE'

// Action Creators
export function setStockMessage(message) {
  return { type: SET_STOCK_MESSAGE, message }
}

// Reducer
const initialState = {
  stockMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STOCK_MESSAGE:
      return {
        ...state,
        stockMessage: action.message,
      }
    default:
      return state
  }
}
