exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-jsx": () => import("./../../../src/pages/account/[...].jsx" /* webpackChunkName: "component---src-pages-account-jsx" */),
  "component---src-pages-app-microform-jsx": () => import("./../../../src/pages/app-microform.jsx" /* webpackChunkName: "component---src-pages-app-microform-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-credit-options-jsx": () => import("./../../../src/pages/credit/options.jsx" /* webpackChunkName: "component---src-pages-credit-options-jsx" */),
  "component---src-pages-credit-synchrony-return-jsx": () => import("./../../../src/pages/credit/synchrony-return.jsx" /* webpackChunkName: "component---src-pages-credit-synchrony-return-jsx" */),
  "component---src-pages-customer-service-common-questions-jsx": () => import("./../../../src/pages/customer-service/common-questions.jsx" /* webpackChunkName: "component---src-pages-customer-service-common-questions-jsx" */),
  "component---src-pages-customer-service-delivery-shipping-jsx": () => import("./../../../src/pages/customer-service/delivery-shipping.jsx" /* webpackChunkName: "component---src-pages-customer-service-delivery-shipping-jsx" */),
  "component---src-pages-customer-service-jsx": () => import("./../../../src/pages/customer-service.jsx" /* webpackChunkName: "component---src-pages-customer-service-jsx" */),
  "component---src-pages-email-unsubscribe-jsx": () => import("./../../../src/pages/email-unsubscribe.jsx" /* webpackChunkName: "component---src-pages-email-unsubscribe-jsx" */),
  "component---src-pages-favorites-jsx": () => import("./../../../src/pages/favorites.jsx" /* webpackChunkName: "component---src-pages-favorites-jsx" */),
  "component---src-pages-gift-cards-purchase-jsx": () => import("./../../../src/pages/gift-cards/purchase.jsx" /* webpackChunkName: "component---src-pages-gift-cards-purchase-jsx" */),
  "component---src-pages-order-details-jsx": () => import("./../../../src/pages/order/details.jsx" /* webpackChunkName: "component---src-pages-order-details-jsx" */),
  "component---src-pages-order-klarna-info-jsx": () => import("./../../../src/pages/order/KlarnaInfo.jsx" /* webpackChunkName: "component---src-pages-order-klarna-info-jsx" */),
  "component---src-pages-order-list-jsx": () => import("./../../../src/pages/order/list.jsx" /* webpackChunkName: "component---src-pages-order-list-jsx" */),
  "component---src-pages-order-payment-jsx": () => import("./../../../src/pages/order/payment.jsx" /* webpackChunkName: "component---src-pages-order-payment-jsx" */),
  "component---src-pages-order-reschedule-jsx": () => import("./../../../src/pages/order/reschedule.jsx" /* webpackChunkName: "component---src-pages-order-reschedule-jsx" */),
  "component---src-pages-order-status-jsx": () => import("./../../../src/pages/order/status.jsx" /* webpackChunkName: "component---src-pages-order-status-jsx" */),
  "component---src-pages-order-success-jsx": () => import("./../../../src/pages/order/success.jsx" /* webpackChunkName: "component---src-pages-order-success-jsx" */),
  "component---src-pages-order-thankyou-jsx": () => import("./../../../src/pages/order/thankyou.jsx" /* webpackChunkName: "component---src-pages-order-thankyou-jsx" */),
  "component---src-pages-sales-store-coupons-jsx": () => import("./../../../src/pages/sales/store-coupons.jsx" /* webpackChunkName: "component---src-pages-sales-store-coupons-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-store-to-cart-input-jsx": () => import("./../../../src/pages/store-to-cart-input.jsx" /* webpackChunkName: "component---src-pages-store-to-cart-input-jsx" */),
  "component---src-templates-dev-pdp-jsx": () => import("./../../../src/templates/dev-pdp.jsx" /* webpackChunkName: "component---src-templates-dev-pdp-jsx" */),
  "component---src-templates-strapi-cms-page-jsx": () => import("./../../../src/templates/strapi-cms/Page.jsx" /* webpackChunkName: "component---src-templates-strapi-cms-page-jsx" */),
  "component---src-templates-strapi-cms-store-jsx": () => import("./../../../src/templates/strapi-cms/Store.jsx" /* webpackChunkName: "component---src-templates-strapi-cms-store-jsx" */),
  "component---src-templates-strapi-cms-stores-jsx": () => import("./../../../src/templates/strapi-cms/Stores.jsx" /* webpackChunkName: "component---src-templates-strapi-cms-stores-jsx" */)
}

