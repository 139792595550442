export const mockLocation1 = {
  lat: 27.9836,
  long: -82.2756,
  city: 'Seffner',
  state: 'Florida',
  county: 'Hillsborough',
  zip: '33584',
  country_code: 'US',
  region: 'FL',
  delivery_zone: 'A',
  price_zone: '0',
  distribution_index: '1',
}

export const mockLocation2 = {
  lat: 33.92904,
  long: -84.3467,
  city: 'Dunwoody',
  state: 'Georgia',
  county: 'Fulton',
  zip: '30346',
  country_code: 'US',
  region: 'SE',
  delivery_zone: 'A',
  price_zone: '2',
  distribution_index: '10',
}

export const mockLocation3 = {
  lat: 29.7594,
  long: -95.363,
  city: 'Houston',
  state: 'Texas',
  country_code: 'US',
  region: 'TX',
  delivery_zone: 'A',
  price_zone: '0',
  distribution_index: '21',
  county: 'Harris',
  browse_only: false,
  zip: '77002',
}
export const mockLocation4 = {
  lat: 39.7512,
  long: -104.9975,
  city: 'Denver',
  state: 'Colorado ',
  country_code: 'US',
  region: 'OOM',
  delivery_zone: 'UPS',
  price_zone: '0',
  distribution_index: '1',
  county: 'Denver',
  browse_only: false,
  zip: '80202',
}

export const mockLocation5 = {
  lat: 18.4274,
  long: -67.1541,
  city: 'Aguidilla',
  state: 'Puerto Rico',
  country_code: 'PR',
  region: 'PR',
  delivery_zone: 'A',
  price_zone: '0',
  distribution_index: '30',
  county: 'Caimit',
  browse_only: false,
  zip: '00603',
}

export const regionLocations = {
  FL: mockLocation1,
  SE: mockLocation2,
  TX: mockLocation3,
  OOM: mockLocation4,
  PR: mockLocation5,
}
